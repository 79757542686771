

















import { defineComponent } from '@nuxtjs/composition-api';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'OrderQuickly',
  setup() {
    const { isRentOrBuy } = useCustomerStore();

    return {
      isRentOrBuy
    }
  }
})
